.Page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
   -webkit-box-pack: center;
      -ms-flex-pack: center;
    justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  width: 100%;
  padding: 20px 0;
  background-color: #FFF;
}

.Page h1 {
  font: bold 40px/190% 'Brittany';
  color: #7C3225;
  padding: 10px;
  -webkit-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
          transform: rotate(-5deg);
  -webkit-animation: fade-in 2.5s ease;
          animation: fade-in 2.5s ease;
}

.Page h2 {
  font: bold 34px 'Inter', sans-serif;
  color: #7C3225;
}

.Page h3 {
  font: bold 20px 'Inter', sans-serif;
  color: #7C3225;
  text-align: center;
  margin: 0;
}

.Page h4 {
  font: bold 16px 'Brittany';
  color: #7C3225;
}

.Page p {
  font: 14px 'Open Sans', sans-serif;
  color: #000;
  text-align: left;
}

.Page ul li {
  font: 14px 'Open Sans', sans-serif;
  color: #000;
  text-align: left;
}

.Page a {
  color: #7C3225;
  text-decoration: none;
}

.Page form {
  width: 45%;
  text-align: left;
}

.Page input {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  background-color: #FFF;
  padding: 20px;
}

.Page input:focus, input:hover {
  outline: 1px solid #9F6256;
}

.Page textarea {
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
}

.Page textarea:hover, textarea:focus {
  outline: 1px solid #9F6256;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 799px) {
  .Page h1 {
    font-size: 32px;
  }

  .Page h3 {
    margin-top: 20px;
  }

  .Page h4 {
    font-size: 16px;
  }

  .Page p {
    font-size: 12px;
  }

  .Page ul li {
    font-size: 12px;
  }

  .Page form {
    width: 70%;
    margin-top: 30px;
  }
}

@media (min-width: 800px) and (min-height: 1100px) {
  .Page form {
    width: 70%;
    margin-top: 30px;
  }
}
