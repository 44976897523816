.NavigationItem {
  margin: 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem a {
  color: #FFF;
  text-decoration: none;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
  font: 600 16px/140% 'Inter', sans-serif;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #000;
}


@media (min-width: 570px) {
  .NavigationItem {
    margin: 0 auto;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    height: 100%;
    width: 110px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
       -ms-flex-align: center;
          align-items: center;
  }

  .NavigationItem a {
    color: #FFF;
    height: 100%;
    padding: 20px 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
       -ms-flex-align: center;
          align-items: center;
    -webkit-box-pack: center;
       -ms-flex-pack: center;
     justify-content: center;
  }

  .NavigationItem a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }

  .NavigationItem a:active,
  .NavigationItem a.active {
    color: #000;
  }
}
