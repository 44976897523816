.Category {
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
   -webkit-box-pack: center;
      -ms-flex-pack: center;
    justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  width: 75%;
}

.Category a {
  text-decoration: none;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  margin: 20px 10px;
  border-radius: 50%;
  position: relative;
}

.Category a:hover,
.Category a:active,
.Category a.active {
  background-color: rgba(220, 204, 192, 0.9);
}

.Category:focus {
  outline: 2px dotted #F5F4F4;
}

.Category a img {
  width: 100%;
  z-index: -1;
}

.Description {
  -webkit-transition: 150ms ease;
  -o-transition: 150ms ease;
  transition: 150ms ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  z-index: 10;
}

.Category a:hover .Description {
  opacity: 1;
}

.Title a {
  margin: 0;
}

.Title a:hover {
  background-color: white !important;
}

.Title a:hover h2 {
  color: #7C3225 !important;
}

.Title:focus {
  outline: 2px dotted #F5F4F4;
}

.Category h2 {
  font: 30px 'Brittany';
  color: #000;
  text-align: center;
}

.Category p {
  font: 12px 'Inter', sans-serif;
  color: #000;
  text-align: center;
}

@media (min-width: 750px) and (max-width: 1000px) {
  .Category {
    width: 45%;
    height: 45%;
  }

  .Category p {
    font-size: 14px;
  }
}


@media (min-width: 1000px) {
  .Category {
    width: 25%;
    height: 25%;
  }

  .Category p {
    font-size: 10px;
  }
}
