.Modal {
  position: fixed;
  top: 30vh;
  left: calc(50% - 15rem);
  width: 30rem;
  background: white;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 100;
  border-radius: 7px;
}

.Modal h2 {
  margin: 0;
  padding: 5%;
  background: #BD9578;
  color: white;
  border-radius: 7px 7px 0 0;
}

.Modal p {
    padding: 10px;
}

.Action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
       -ms-flex-pack: end;
     justify-content: flex-end;
    padding: 5px 10px;
}

.Modal button {
  font: inherit;
  background: #BD9578;
  padding: 8px 20px;
  color: white;
  border: 1px solid #BD9578;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
}

.Modal button:hover,
button:active {
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
}

.Modal button:focus {
  outline: none;
}

@media (max-width: 570px) {
  .Modal {
    left: calc(50% - 7.5rem);
    width: 250px;
  }

  .Modal h2 {
    font-size: 16px;
  }

  .Modal p {
    font-size: 12px;
  }

  .Modal button {
    font-size: 12px;
  }
}
