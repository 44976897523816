.SocialBar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
   -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  width: 100%;
  position: relative;
}

.SocialBar button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 30px auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  background-color: inherit;
}

.SocialBar button img {
  width: 100%;
}

.SocialBar button:hover, button:focus, button:active {
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

@media (min-width: 570px) {
  .SocialBar {
     -webkit-box-pack: justify;
        -ms-flex-pack: justify;
      justify-content: space-between;
  }

  .SocialBar button {
    width: 35px;
    padding: 0 5px;
  }
}
