.BlogItem {
  margin: 10px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  height: 100%;
}

.BlogItem button {
  color: #000;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: none;
  outline: none;
  font: 600 14px 'Inter', sans-serif;
  background-color: #FFF;
}

.BlogItem button:hover,
.BlogItem button:active,
.BlogItem button.active,
.BlogItem button:focus {
  color: #9F6256;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
