.Content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
   -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  -webkit-box-align: start;
     -ms-flex-align: start;
        align-items: flex-start;
  width: 90%;
  padding: 2% 5%;
  margin-bottom: 30px;
}

.Content form {
  -webkit-animation: slide-in-bottom 1s ease;
          animation: slide-in-bottom 1s ease;
}

.Text {
  width: 45%;
  -webkit-animation: slide-in-right 1s ease;
          animation: slide-in-right 1s ease;
}

.ImageLeft img {
  width: 100%;
  border: 5px solid #9F6256;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ImageLeft {
  width: 45%;
  -webkit-animation: slide-in-left 1s ease;
          animation: slide-in-left 1s ease;
}

.ImageTop img {
  width: 100%;
  border: 5px solid #9F6256;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.ImageTop {
  width: 45%;
  -webkit-animation: slide-in-top 1s ease;
          animation: slide-in-top 1s ease;
}

@-webkit-keyframes slide-in-left {
   0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide-in-left {
   0% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-right {
   0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide-in-right {
   0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-top {
   0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-in-top {
   0% {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes slide-in-bottom {
   0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-in-bottom {
   0% {
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@media (max-width: 799px) {
  .Content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
       -ms-flex-align: center;
          align-items: center;
    padding: 0;
  }

  .Text {
    width: 70%;
  }

  .ImageTop, .ImageLeft {
    width: 70%;
  }
}

@media (min-width: 800px) and (min-height: 1100px) {
  .Content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: center;
       -ms-flex-align: center;
          align-items: center;
    padding: 0;
  }

  .Text {
    width: 70%;
  }

  .ImageTop, .ImageLeft {
    width: 70%;
  }
}
