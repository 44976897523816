.Subscribe {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.Subscribe p {
  font-size: 14px;
  margin: 0;
  color: #fff;
}

.Subscribe input {
  padding: 5px 0;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: inherit;
  border: none;
  height: 100%;
  width: 90%;
}

.Subscribe input:focus,
input:hover {
  border: none;
  outline: none;
}

.Subscribe button[type="submit"] {
  width: 0;
  height: 0;
  padding: 0;
  margin-right: 5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #fff;
  border-right: 0 solid transparent;
  background-color: inherit;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
}

.Subscribe button[type="submit"]:hover {
  outline: none;
  -webkit-box-shadow: -2px 0px #ccc;
  box-shadow: -2px 0px #ccc;
}

.Subscribe button[type="submit"]:active,
.Subscribe button[type="submit"]:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.Send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 auto;
  padding-top: 30px;
  width: 80%;
  border-bottom: 1px solid #fff;
}
