.Toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
   -webkit-box-pack: justify;
      -ms-flex-pack: justify;
    justify-content: space-between;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #BD9578;
  padding: 0 20px;
  z-index: 90;
}

.Toolbar nav {
  height: 80%;
}

.Logo {
  height: 100%;
}

.SocialBar {
  height: 80%;
}

@media (max-width: 570px) {
  .DesktopOnly {
    display: none;
  }

  .Logo {
    height: 70%;
  }
}

@media (max-width: 850px) {
  .SocialBar {
    display: none;
  }
}

@media (min-width: 571px) and (max-width: 850px) {
  .Toolbar {
      -ms-flex-pack: distribute;
    justify-content: space-around;
  }
}
