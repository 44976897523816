.Content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
     -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
     -ms-flex-direction: column;
         flex-direction: column;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
 -webkit-box-align: center;
    -ms-flex-align: center;
       align-items: center;
  width: 95%;
  min-height: 90vh;
  margin: 20px auto;
  padding: 0;
  -webkit-animation: fade-in 0.8s ease;
          animation: fade-in 0.8s ease;
}

 @-webkit-keyframes fade-in {
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }

 @keyframes fade-in {
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }

@media (max-width: 624px) {
  .Content {
    width: 85%;
  }
}
