.SubscribeModal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
     -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
     -ms-flex-pack: justify;
   justify-content: space-between;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  position: fixed;
  top: 30vh;
  left: calc(50% - 20rem);
  background: white;
  width: 650px;
  height: 250px;
  z-index: 100;
}

.SubscribeModal h2 {
  font: 20px 'Abril Fatface', cursive;
  padding-top: 30px;
}

.SubscribeModal p {
  font: 14px 'Inter', sans-serif;
  padding: 0 20%;
}

.SubscribeModal img {
  width: 50%;
  height: 100%;
}

.SubscribeModal input {
  padding: 5px 0;
  font-family: 'Inter', sans-serif;
  border: none;
  height: 100%;
  width: 80%;
}

.SubscribeModal input:focus {
  border: none;
  outline: none;
}

.SubscribeModal button[type=submit] {
  width: 0;
  height: 0;
  padding: 0;
  margin-right: 5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #000;
  border-right: 0 solid transparent;
  background-color: #FFF;
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
}

.SubscribeModal button[type=submit]:hover {
  outline: none;
  -webkit-box-shadow: -2px 0px #CCC;
          box-shadow: -2px 0px #CCC;
}

.SubscribeModal button[type=submit]:active,
.SubscribeModal button[type=submit]:focus {
  outline: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.Send {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
     -ms-flex-pack: justify;
   justify-content: space-between;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  margin: 0 auto;
  padding-top: 30px;
  width: 80%;
  border-bottom: 1px solid #000;
}

.Content {
  width: 50%;
  height: 100%;
  padding: 20px 0;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: none;
}

.CloseButton .barOne {
  height: 2px;
  background-color: #FFF;
  -webkit-transform: translateY(7px) rotate(45deg);
      -ms-transform: translateY(7px) rotate(45deg);
          transform: translateY(7px) rotate(45deg);
}

.CloseButton .barTwo {
  height: 2px;
  background-color: #FFF;
  -webkit-transform: translateY(5px) rotate(-45deg);
      -ms-transform: translateY(5px) rotate(-45deg);
          transform: translateY(5px) rotate(-45deg);
}

@media (min-width: 560px) and (max-width: 750px) {
  .SubscribeModal {
    width: 500px;
    height: 200px;
    left: calc(50% - 15rem);
  }

  .SubscribeModal h2 {
    font-size: 16px;
    padding-top: 20px;
  }

  .SubscribeModal p {
    font-size: 12px;
  }
}

@media (max-width: 560px) {
  .SubscribeModal {
    width: 290px;
    height: 450px;
    top: 20vh;
    left: calc(50% - 9rem);
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-flow: column-reverse;
            flex-flow: column-reverse;
  }

  .SubscribeModal img {
    width: 100%;
    height: 50%;
  }

  .SubscribeModal h2 {
    padding-top: 5px;
  }

  .Content {
    width: 100%;
    height: 50%;
  }
}
