.Button {
  background-color: #BD9578;
  color: #FFF;
  height: 45px;
  font: bold 14px 'Inter', sans-serif;
  border-radius: 5px;
  border: 1px solid #9F6256;
  cursor: pointer;
}

.Button:hover, .Button:focus {
  border: 2px solid #7C3225;
}

.Button:disabled {
  cursor: not-allowed;
}

.Submit {
  width: 100%;
}

.Comment, .Reply {
  margin-top: 10px;
}

@media (max-width: 700px) {
  .Comment {
    width: 90%;
  }

  .Reply {
    width: 100%;
  }
}
