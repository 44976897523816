.BlogItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
   -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0 auto;
  padding: 20px 20px 10px 20px;
  list-style: none;
  width: 80%;
  -webkit-animation: fade-in 0.8s ease;
          animation: fade-in 0.8s ease;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 570px) {
  .BlogItems {
    -webkit-box-pack: center;
       -ms-flex-pack: center;
     justify-content: center;
  }
}
