.App {
  text-align: center;
  padding-bottom: 200px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 100%;
}

@media (max-width: 700px) {
  .App {
    padding-bottom: 158px;
  }
}
