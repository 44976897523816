.SideDrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: #bd9578;
  padding: 32px 16px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.CloseButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: inherit;
}

.CloseButton:hover {
  -webkit-animation: spin 0.8s ease;
  animation: spin 0.8s ease;
}

.CloseButton .barOne {
  height: 2px;
  background-color: #000;
  -webkit-transform: translateY(12px) rotate(45deg);
  -ms-transform: translateY(12px) rotate(45deg);
  transform: translateY(12px) rotate(45deg);
}

.CloseButton .barTwo {
  height: 2px;
  background-color: #000;
  -webkit-transform: translateY(10px) rotate(-45deg);
  -ms-transform: translateY(10px) rotate(-45deg);
  transform: translateY(10px) rotate(-45deg);
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: center;
    transform-origin: center;
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: center;
    transform-origin: center;
  }
}

@media (min-width: 570px) {
  .SideDrawer {
    display: none;
  }
}

.Open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.Close {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.Logo {
  height: 11%;
  margin-bottom: 32px;
}

.SocialBar {
  margin: 0 auto;
  width: 80%;
}

.Subscribe {
  margin: 0 auto;
  width: 80%;
}
