.Comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
}

.Heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Heading h4 {
  font: 14px 'Abril Fatface', cursive;
  margin: 0;
  margin-right: 10px;
  padding: 0;
}

.Heading p {
  font: 12px 'Inter', sans-serif;
  color: #7C3225;
  margin: 0;
  padding: 0;
}

.Heading button {
  font: 12px 'Inter', sans-serif;
  color: #7C3225;
  background-color: #FFF;
  border: none;
  margin: 0;
  padding: 0;
}

.Heading button:hover,
.Heading button:focus {
  outline: none;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.Details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 70%;
}

.Content {
  width: 100%;
}

.Content p {
  font: 12px 'Inter', sans-serif;
  color: #000;
  text-align: left;
}
