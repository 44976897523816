.Preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 32%;
  padding: 0 20px;
  margin: 10px 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  min-height: 390px;
  -webkit-animation: fade-in 0.8s ease;
          animation: fade-in 0.8s ease;
}

.Preview:focus {
  outline: 2px dotted #F5F4F4;
}

.Preview h1 {
  font: bold 20px 'Playfair Display', sans-serif;
  color: #000;
  text-align: left;
  margin: 0;
}

.Preview h3 {
  font: bold 16px 'Inter', sans-serif;
  color: #9F6256;
  margin: 0 0 20px 0;
}

.Preview p {
  font: 14px 'Inter', sans-serif;
  text-align: left;
  width: 100%;
}

.Preview img {
  width: 100%;
  margin-bottom: 10px;
}

.Preview a {
  color: #7C3225;
  text-decoration: none;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 625px) and (max-width: 1000px) {
  .Preview{
    width: 45%;
    margin: 10px 10px 10px 15px;
  }
}

@media (max-width: 624px) {
  .Preview {
    width: 100%;
    margin: 10px 0;
    min-height: 410px;
  }
}
