.Image {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: zoom-out 0.8s ease-in-out;
          animation: zoom-out 0.8s ease-in-out;
}

.Image img {
  width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@-webkkit-keyframes zoom-out {
  0% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes zoom-out {
  0% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
