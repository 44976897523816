.Preview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 32%;
  padding: 0 20px;
  margin: 10px 5px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}


.Preview h1 {
  font: bold 20px 'Playfair Display', sans-serif;
  color: #000;
  text-align: left;
  margin: 0;
}

.Preview h3 {
  font: bold 16px 'Inter', sans-serif;
  color: #9F6256;
  margin: 0 0 20px 0;
}

.Preview p {
  font: 14px 'Inter', sans-serif;
  text-align: left;
  width: 100%;
}

.Reply, .Comment {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Comment {
  width: 100%;
}

.Reply input, .Comment input {
  width: 90%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  background-color: #FFF;
  padding: 10px;
  margin-top: 10px;
}

.Reply input:focus,
.Reply input:hover,
.Comment input:focus,
.Comment input:hover {
  outline: 1px solid #9F6256;
}

.Reply textarea,
.Comment textarea {
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 90%;
}

.Reply textarea:hover,
.Reply textarea:focus,
.Comment textarea:hover,
.Comment textarea:focus {
  outline: 1px solid #9F6256;
}

.Reply button[type=cancel],
.Comment button[type=cancel] {
  background-color: #FFF;
  color: #9F6256;
  font: 18px 'Abril Fatface', cursive;
  border: none;
  outline: none;
}

.Reply button[type=cancel]:hover,
.Reply button[type=cancel]:focus,
.Comment button[type=cancel]:hover,
.Comment button[type=cancel][type=cancel]:focus {
  outline: none;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.Header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
}

.Header h3 {
  font: 18px 'Abril Fatface', cursive;
  text-align: left;
}

.Options {
  width: 60%;
  text-align: left;
}

.Options input[type=checkbox] {
  width: auto;
  padding: 0;
  margin: 0;
}

.Options input[type=checkbox]:hover {
  cursor: pointer;
  outline: none;
}

.Options input[type=checkbox]:checked {
  background-color: #BD9578;
}

.Options label {
  font: 12px 'Inter', sans-serif;
  text-align: left;
  margin: 0;
  padding: 0;
  width: 90%;
}

.Submit {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 90%;
  padding: 30px 0 40px 0;
}

@media (max-width: 700px) {
  .Reply {
    width: 90%;
  }

  .Comment {
    width: 100%;
  }

  .Reply button[type=cancel],
  .Comment button[type=cancel] {
    font-size: 16px;
  }

  .Reply input {
    width: 100%;
  }

  .Reply textarea {
    width: 100%;
  }

  .Header {
    width: 100%;
  }

  .Header h3 {
    font-size: 16px;
  }

  .Options {
    width: 100%;
  }

  .Submit {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column;
            flex-flow: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    width: 100%;
  }
}
