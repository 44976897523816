.TopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #BD9578;
  width: 50px;
  height: 50px;
  z-index: 100;
}

.TopButton button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: #BD9578;
}

.TopButton button:hover, button:focus, button:active {
  border: none;
  outline: none;
}

.BarLeft {
  margin: 0;
  height: 5px;
  width: 40%;
  background-color: #FFF;
  border-radius: 2px;
  -webkit-transform: translate(5px, -2px) rotate(-45deg);
      -ms-transform: translate(5px, -2px) rotate(-45deg);
          transform: translate(5px, -2px) rotate(-45deg);
}

.BarRight {
  margin: 0;
  height: 5px;
  width: 40%;
  background-color: #FFF;
  border-radius: 2px;
  -webkit-transform: translate(-4px, -2px) rotate(45deg);
      -ms-transform: translate(-4px, -2px) rotate(45deg);
          transform: translate(-4px, -2px) rotate(45deg);
}
