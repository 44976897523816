.DrawerToggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 30px;
  height: 70%;
  padding: 10px 0;
  cursor: pointer;
}

.DrawerToggle div {
  width: 90%;
  height: 3px;
  background-color: white;
}

@media (min-width: 570px) {
    .DrawerToggle {
      display: none;
    }
}
