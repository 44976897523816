.SocialBarItem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin: 30px auto;
  width: 30px;
}

.SocialBarItem a {
  text-decoration: none;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: block;
}

.SocialBarItem a:hover {
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);
}

.SocialBarItem a img {
  width: 100%;
}


@media (min-width: 570px) {
  .SocialBarItem {
    margin: 0 auto;
    height: 100%;
    width: 45px;
  }

  .SocialBarItem a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
       -ms-flex-align: center;
          align-items: center;
     -webkit-box-pack: center;
        -ms-flex-pack: center;
      justify-content: center;
    padding: 10px;
  }
}
