.Footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 30px 45px;
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
  font: 18px "Inter", sans-serif;
  text-align: center;
  z-index: 90;
}

.Footer h2 {
  font: bold 36px "Brittany";
  margin: 0;
}

.Footer h3 {
  font: bold 24px "Brittany";
  margin: 0;
  margin-bottom: 20px;
}

.Subscribe {
  width: 20%;
  height: 50%;
}

.Signature {
  height: 100%;
}

.SocialBar {
  height: 50%;
}

@media (max-width: 700px) {
  .Footer {
    font-size: 12px;
    padding: 30px;
  }

  .Footer h2 {
    font-size: 32px;
  }

  .Subscribe {
    display: none;
  }

  .SocialBar {
    display: none;
  }
}
