@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap);
@font-face {
  font-family: 'Brittany';
  src: url(/static/media/BrittanySignature.b4d584ed.ttf);
}

html, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  height: calc(100% - 80px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Layout_Content__3H3X8 {
  margin-top: 80px;
}

.Logo_Logo__1N0xH {
  padding: 0;
  height: 100%;
  box-sizing: border-box;
}

.Logo_Logo__1N0xH img {
  height: 100%;
}

.NavigationItems_NavigationItems__1fnFX {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
}

@media (min-width: 570px) {
  .NavigationItems_NavigationItems__1fnFX {
    flex-flow: row;
  justify-content: space-between;
    width: 550px;
  }
}

.NavigationItem_NavigationItem__2SpXc {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.NavigationItem_NavigationItem__2SpXc a {
  color: #FFF;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font: 600 16px/140% 'Inter', sans-serif;
}

.NavigationItem_NavigationItem__2SpXc a:hover,
.NavigationItem_NavigationItem__2SpXc a:active,
.NavigationItem_NavigationItem__2SpXc a.NavigationItem_active__2v2td {
  color: #000;
}


@media (min-width: 570px) {
  .NavigationItem_NavigationItem__2SpXc {
    margin: 0 auto;
    box-sizing: border-box;
    height: 100%;
    width: 110px;
    display: flex;
    align-items: center;
  }

  .NavigationItem_NavigationItem__2SpXc a {
    color: #FFF;
    height: 100%;
    padding: 20px 10px;
    display: flex;
    align-items: center;
  justify-content: center;
  }

  .NavigationItem_NavigationItem__2SpXc a:hover {
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
  }

  .NavigationItem_NavigationItem__2SpXc a:active,
  .NavigationItem_NavigationItem__2SpXc a.NavigationItem_active__2v2td {
    color: #000;
  }
}

.SocialBar_SocialBar__1i7PZ {
  display: flex;
  flex-flow: row;
 justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
  width: 100%;
  position: relative;
}

.SocialBar_SocialBar__1i7PZ button {
  display: flex;
justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 30px auto;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  background-color: inherit;
}

.SocialBar_SocialBar__1i7PZ button img {
  width: 100%;
}

.SocialBar_SocialBar__1i7PZ button:hover, button:focus, button:active {
  border: none;
  outline: none;
  box-shadow: none;
  transform: scale(1.2);
}

@media (min-width: 570px) {
  .SocialBar_SocialBar__1i7PZ {
   justify-content: space-between;
  }

  .SocialBar_SocialBar__1i7PZ button {
    width: 35px;
    padding: 0 5px;
  }
}

.SocialBarItem_SocialBarItem__3rYRh {
  display: flex;
justify-content: center;
  box-sizing: border-box;
  margin: 30px auto;
  width: 30px;
}

.SocialBarItem_SocialBarItem__3rYRh a {
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.SocialBarItem_SocialBarItem__3rYRh a:hover {
  transform: scale(1.2);
}

.SocialBarItem_SocialBarItem__3rYRh a img {
  width: 100%;
}


@media (min-width: 570px) {
  .SocialBarItem_SocialBarItem__3rYRh {
    margin: 0 auto;
    height: 100%;
    width: 45px;
  }

  .SocialBarItem_SocialBarItem__3rYRh a {
    display: flex;
    align-items: center;
   justify-content: center;
    padding: 10px;
  }
}

.SearchBar_SearchBar__3_Vkw {
  width: 150px;
  position: absolute;
  right: 0;
  bottom: -45px;
  opacity: 0;
  transition: opacity 0.3s linear;
}

.SearchBar_SearchBar__3_Vkw input {
  width: 100%;
  padding: 10px;
  font: 12px 'Inter', sans-serif;
  background-color: #FFF;
  border: 1px solid rgb(230, 230, 230);
  box-shadow: 0px 1px 2px #CCC;
  box-sizing: border-box;
}

.SearchBar_SearchBar__3_Vkw input:focus, input:hover {
  outline: 1px solid #9F6256;
}

.SearchBar_Show__2aUxz {
  opacity: 1;
  transition: opacity 0.3s linear;
}

@media (max-width: 499px) {
  .SearchBar_SearchBar__3_Vkw {
    right: -10px;
    bottom: -20px;
  }
}

.DrawerToggle_DrawerToggle__m405X {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 70%;
  padding: 10px 0;
  cursor: pointer;
}

.DrawerToggle_DrawerToggle__m405X div {
  width: 90%;
  height: 3px;
  background-color: white;
}

@media (min-width: 570px) {
    .DrawerToggle_DrawerToggle__m405X {
      display: none;
    }
}

.Toolbar_Toolbar__ApScI {
  display: flex;
 justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #BD9578;
  padding: 0 20px;
  z-index: 90;
}

.Toolbar_Toolbar__ApScI nav {
  height: 80%;
}

.Toolbar_Logo__3Lk47 {
  height: 100%;
}

.Toolbar_SocialBar__2mrwV {
  height: 80%;
}

@media (max-width: 570px) {
  .Toolbar_DesktopOnly__LuPaL {
    display: none;
  }

  .Toolbar_Logo__3Lk47 {
    height: 70%;
  }
}

@media (max-width: 850px) {
  .Toolbar_SocialBar__2mrwV {
    display: none;
  }
}

@media (min-width: 571px) and (max-width: 850px) {
  .Toolbar_Toolbar__ApScI {
    justify-content: space-around;
  }
}

.Subscribe_Subscribe__YiKmB {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.Subscribe_Subscribe__YiKmB p {
  font-size: 14px;
  margin: 0;
  color: #fff;
}

.Subscribe_Subscribe__YiKmB input {
  padding: 5px 0;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: inherit;
  border: none;
  height: 100%;
  width: 90%;
}

.Subscribe_Subscribe__YiKmB input:focus,
input:hover {
  border: none;
  outline: none;
}

.Subscribe_Subscribe__YiKmB button[type="submit"] {
  width: 0;
  height: 0;
  padding: 0;
  margin-right: 5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #fff;
  border-right: 0 solid transparent;
  background-color: inherit;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.Subscribe_Subscribe__YiKmB button[type="submit"]:hover {
  outline: none;
  box-shadow: -2px 0px #ccc;
}

.Subscribe_Subscribe__YiKmB button[type="submit"]:active,
.Subscribe_Subscribe__YiKmB button[type="submit"]:focus {
  outline: none;
  box-shadow: none;
}

.Subscribe_Send__ddqtm {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-top: 30px;
  width: 80%;
  border-bottom: 1px solid #fff;
}

.Backdrop_Backdrop__3j6VK {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.SideDrawer_SideDrawer__3kXLK {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: #bd9578;
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
}

.SideDrawer_CloseButton__2VUJE {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 25px;
  height: 25px;
  background-color: inherit;
}

.SideDrawer_CloseButton__2VUJE:hover {
  -webkit-animation: SideDrawer_spin__3wj_z 0.8s ease;
  animation: SideDrawer_spin__3wj_z 0.8s ease;
}

.SideDrawer_CloseButton__2VUJE .SideDrawer_barOne__29t75 {
  height: 2px;
  background-color: #000;
  transform: translateY(12px) rotate(45deg);
}

.SideDrawer_CloseButton__2VUJE .SideDrawer_barTwo__3n4N- {
  height: 2px;
  background-color: #000;
  transform: translateY(10px) rotate(-45deg);
}

@-webkit-keyframes SideDrawer_spin__3wj_z {
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

@keyframes SideDrawer_spin__3wj_z {
  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
}

@media (min-width: 570px) {
  .SideDrawer_SideDrawer__3kXLK {
    display: none;
  }
}

.SideDrawer_Open__1tCv1 {
  transform: translateX(0);
}

.SideDrawer_Close__9j7x- {
  transform: translateX(-100%);
}

.SideDrawer_Logo__3voUv {
  height: 11%;
  margin-bottom: 32px;
}

.SideDrawer_SocialBar__3hG9A {
  margin: 0 auto;
  width: 80%;
}

.SideDrawer_Subscribe__1O6RV {
  margin: 0 auto;
  width: 80%;
}

.Image_Image__3-K0N {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-animation: Image_zoom-out__2I6CK 0.8s ease-in-out;
          animation: Image_zoom-out__2I6CK 0.8s ease-in-out;
}

.Image_Image__3-K0N img {
  width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@-webkkit-keyframes Image_zoom-out__2I6CK {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes Image_zoom-out__2I6CK {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes Image_zoom-out__2I6CK {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.Welcome_Welcome__EjqLC {
  display: flex;
justify-content: center;
  align-items: center;
  position: relative;
}

.Welcome_Welcome__EjqLC img {
  width: 100%;
  height: 500px;
}

.Welcome_Transbox__2A75u {
  display: flex;
  flex-flow: column;
justify-content: center;
  align-items: center;
  width: 100%;
  background-color: rgba(220, 204, 192, 0.75);
  -webkit-animation: Welcome_fade-in__33-Ir 0.8s ease;
          animation: Welcome_fade-in__33-Ir 0.8s ease;
}

.Welcome_Transbox__2A75u h1 {
  font: bold 48px 'Brittany';
  text-shadow: 1px 2px rgba(124, 50, 37, 0.4);
  color: #7C3225;
  transform: rotate(-5deg);
}

.Welcome_Transbox__2A75u p {
  font: 400 18px/140% 'Open Sans', sans-serif;
  padding-bottom: 5%;
  width: 75%;
}

@-webkit-keyframes Welcome_fade-in__33-Ir {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Welcome_fade-in__33-Ir {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 799px) {
  .Welcome_Welcome__EjqLC img {
    display: none;
  }
}

@media (min-width: 800px) {
  .Welcome_Transbox__2A75u h1 {
    font-size: 58px;
  }

  .Welcome_Transbox__2A75u {
    position: absolute;
    top: 15%;
    left: 10%;
    width: 80%;
    border-radius: 5px;
  }
}

.Categories {
  display: flex;
  flex-flow: column;
  align-items: center;
  margin: 0;
  padding: 5% 1%;
  height: 100%;
  list-style: none;
}

@media (min-width: 800px) {
  .Categories {
    flex-flow: row wrap;
  justify-content: space-evenly;
  }
}

.Category {
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
 justify-content: center;
  align-items: center;
  width: 75%;
}

.Category a {
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
justify-content: center;
  margin: 20px 10px;
  border-radius: 50%;
  position: relative;
}

.Category a:hover,
.Category a:active,
.Category a.active {
  background-color: rgba(220, 204, 192, 0.9);
}

.Category:focus {
  outline: 2px dotted #F5F4F4;
}

.Category a img {
  width: 100%;
  z-index: -1;
}

.Description {
  transition: 150ms ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  z-index: 10;
}

.Category a:hover .Description {
  opacity: 1;
}

.Title a {
  margin: 0;
}

.Title a:hover {
  background-color: white !important;
}

.Title a:hover h2 {
  color: #7C3225 !important;
}

.Title:focus {
  outline: 2px dotted #F5F4F4;
}

.Category h2 {
  font: 30px 'Brittany';
  color: #000;
  text-align: center;
}

.Category p {
  font: 12px 'Inter', sans-serif;
  color: #000;
  text-align: center;
}

@media (min-width: 750px) and (max-width: 1000px) {
  .Category {
    width: 45%;
    height: 45%;
  }

  .Category p {
    font-size: 14px;
  }
}


@media (min-width: 1000px) {
  .Category {
    width: 25%;
    height: 25%;
  }

  .Category p {
    font-size: 10px;
  }
}

.TopButton_TopButton__3Tv3X {
  position: fixed;
  bottom: 20px;
  right: 20px;
  margin: 0;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: #BD9578;
  width: 50px;
  height: 50px;
  z-index: 100;
}

.TopButton_TopButton__3Tv3X button {
  display: flex;
  align-items: center;
justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: none;
  outline: none;
  background-color: #BD9578;
}

.TopButton_TopButton__3Tv3X button:hover, button:focus, button:active {
  border: none;
  outline: none;
}

.TopButton_BarLeft__oAG3s {
  margin: 0;
  height: 5px;
  width: 40%;
  background-color: #FFF;
  border-radius: 2px;
  transform: translate(5px, -2px) rotate(-45deg);
}

.TopButton_BarRight__qs32k {
  margin: 0;
  height: 5px;
  width: 40%;
  background-color: #FFF;
  border-radius: 2px;
  transform: translate(-4px, -2px) rotate(45deg);
}

.SubscribeModal_SubscribeModal__Y6pdn {
  display: flex;
  flex-flow: row;
justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 30vh;
  left: calc(50% - 20rem);
  background: white;
  width: 650px;
  height: 250px;
  z-index: 100;
}

.SubscribeModal_SubscribeModal__Y6pdn h2 {
  font: 20px 'Abril Fatface', cursive;
  padding-top: 30px;
}

.SubscribeModal_SubscribeModal__Y6pdn p {
  font: 14px 'Inter', sans-serif;
  padding: 0 20%;
}

.SubscribeModal_SubscribeModal__Y6pdn img {
  width: 50%;
  height: 100%;
}

.SubscribeModal_SubscribeModal__Y6pdn input {
  padding: 5px 0;
  font-family: 'Inter', sans-serif;
  border: none;
  height: 100%;
  width: 80%;
}

.SubscribeModal_SubscribeModal__Y6pdn input:focus {
  border: none;
  outline: none;
}

.SubscribeModal_SubscribeModal__Y6pdn button[type=submit] {
  width: 0;
  height: 0;
  padding: 0;
  margin-right: 5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 8px solid #000;
  border-right: 0 solid transparent;
  background-color: #FFF;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}

.SubscribeModal_SubscribeModal__Y6pdn button[type=submit]:hover {
  outline: none;
  box-shadow: -2px 0px #CCC;
}

.SubscribeModal_SubscribeModal__Y6pdn button[type=submit]:active,
.SubscribeModal_SubscribeModal__Y6pdn button[type=submit]:focus {
  outline: none;
  box-shadow: none;
}

.SubscribeModal_Send__2-w-A {
  display: flex;
  flex-flow: row;
justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-top: 30px;
  width: 80%;
  border-bottom: 1px solid #000;
}

.SubscribeModal_Content__19j7H {
  width: 50%;
  height: 100%;
  padding: 20px 0;
}

.SubscribeModal_CloseButton__3TGbz {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  background-color: none;
}

.SubscribeModal_CloseButton__3TGbz .SubscribeModal_barOne__3xg8U {
  height: 2px;
  background-color: #FFF;
  transform: translateY(7px) rotate(45deg);
}

.SubscribeModal_CloseButton__3TGbz .SubscribeModal_barTwo__1QLYf {
  height: 2px;
  background-color: #FFF;
  transform: translateY(5px) rotate(-45deg);
}

@media (min-width: 560px) and (max-width: 750px) {
  .SubscribeModal_SubscribeModal__Y6pdn {
    width: 500px;
    height: 200px;
    left: calc(50% - 15rem);
  }

  .SubscribeModal_SubscribeModal__Y6pdn h2 {
    font-size: 16px;
    padding-top: 20px;
  }

  .SubscribeModal_SubscribeModal__Y6pdn p {
    font-size: 12px;
  }
}

@media (max-width: 560px) {
  .SubscribeModal_SubscribeModal__Y6pdn {
    width: 290px;
    height: 450px;
    top: 20vh;
    left: calc(50% - 9rem);
    flex-flow: column-reverse;
  }

  .SubscribeModal_SubscribeModal__Y6pdn img {
    width: 100%;
    height: 50%;
  }

  .SubscribeModal_SubscribeModal__Y6pdn h2 {
    padding-top: 5px;
  }

  .SubscribeModal_Content__19j7H {
    width: 100%;
    height: 50%;
  }
}

.Loader_Container__1_wX0 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  padding: 30px;
}

.Loader_Loader__ANdA0 {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: Loader_Loader__ANdA0 2.5s infinite linear both;
  animation: Loader_Loader__ANdA0 2.5s infinite linear both;
}

.Loader_LoaderDot__1AKFG {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: Loader_LoaderDot__1AKFG 2.0s infinite ease-in-out both;
  animation: Loader_LoaderDot__1AKFG 2.0s infinite ease-in-out both;
}

.Loader_LoaderDot__1AKFG:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #000;
  border-radius: 100%;
  -webkit-animation: Loader_LoaderDot-before__155y9 2.0s infinite ease-in-out both;
  animation: Loader_LoaderDot-before__155y9 2.0s infinite ease-in-out both;
}

.Loader_LoaderDot__1AKFG:nth-child(1) { -webkit-animation-delay: -1.1s;animation-delay: -1.1s; }
.Loader_LoaderDot__1AKFG:nth-child(2) { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
.Loader_LoaderDot__1AKFG:nth-child(3) { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
.Loader_LoaderDot__1AKFG:nth-child(4) { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
.Loader_LoaderDot__1AKFG:nth-child(5) { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
.Loader_LoaderDot__1AKFG:nth-child(6) { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }
.Loader_LoaderDot__1AKFG:nth-child(1):before { -webkit-animation-delay: -1.1s; animation-delay: -1.1s; }
.Loader_LoaderDot__1AKFG:nth-child(2):before { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
.Loader_LoaderDot__1AKFG:nth-child(3):before { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
.Loader_LoaderDot__1AKFG:nth-child(4):before { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
.Loader_LoaderDot__1AKFG:nth-child(5):before { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
.Loader_LoaderDot__1AKFG:nth-child(6):before { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }

@-webkit-keyframes Loader_Loader__ANdA0 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes Loader_Loader__ANdA0 {
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Loader_LoaderDot__1AKFG {
  80%, 100% {
    transform: rotate(360deg);
  }
}

@keyframes Loader_LoaderDot__1AKFG {
  80%, 100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes Loader_LoaderDot-before__155y9 {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}

@keyframes Loader_LoaderDot-before__155y9 {
  50% {
    transform: scale(0.4);
  } 100%, 0% {
    transform: scale(1.0);
  }
}

.Page_Page__3YWRY {
  display: flex;
  flex-flow: column;
 justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background-color: #FFF;
}

.Page_Page__3YWRY h1 {
  font: bold 40px/190% 'Brittany';
  color: #7C3225;
  padding: 10px;
  transform: rotate(-5deg);
  -webkit-animation: Page_fade-in__1_tU2 2.5s ease;
          animation: Page_fade-in__1_tU2 2.5s ease;
}

.Page_Page__3YWRY h2 {
  font: bold 34px 'Inter', sans-serif;
  color: #7C3225;
}

.Page_Page__3YWRY h3 {
  font: bold 20px 'Inter', sans-serif;
  color: #7C3225;
  text-align: center;
  margin: 0;
}

.Page_Page__3YWRY h4 {
  font: bold 16px 'Brittany';
  color: #7C3225;
}

.Page_Page__3YWRY p {
  font: 14px 'Open Sans', sans-serif;
  color: #000;
  text-align: left;
}

.Page_Page__3YWRY ul li {
  font: 14px 'Open Sans', sans-serif;
  color: #000;
  text-align: left;
}

.Page_Page__3YWRY a {
  color: #7C3225;
  text-decoration: none;
}

.Page_Page__3YWRY form {
  width: 45%;
  text-align: left;
}

.Page_Page__3YWRY input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  background-color: #FFF;
  padding: 20px;
}

.Page_Page__3YWRY input:focus, input:hover {
  outline: 1px solid #9F6256;
}

.Page_Page__3YWRY textarea {
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
}

.Page_Page__3YWRY textarea:hover, textarea:focus {
  outline: 1px solid #9F6256;
}

@-webkit-keyframes Page_fade-in__1_tU2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Page_fade-in__1_tU2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 799px) {
  .Page_Page__3YWRY h1 {
    font-size: 32px;
  }

  .Page_Page__3YWRY h3 {
    margin-top: 20px;
  }

  .Page_Page__3YWRY h4 {
    font-size: 16px;
  }

  .Page_Page__3YWRY p {
    font-size: 12px;
  }

  .Page_Page__3YWRY ul li {
    font-size: 12px;
  }

  .Page_Page__3YWRY form {
    width: 70%;
    margin-top: 30px;
  }
}

@media (min-width: 800px) and (min-height: 1100px) {
  .Page_Page__3YWRY form {
    width: 70%;
    margin-top: 30px;
  }
}

.Content {
  display: flex;
  flex-flow: row wrap;
 justify-content: space-evenly;
  align-items: flex-start;
  width: 90%;
  padding: 2% 5%;
  margin-bottom: 30px;
}

.Content form {
  -webkit-animation: slide-in-bottom 1s ease;
          animation: slide-in-bottom 1s ease;
}

.Text {
  width: 45%;
  -webkit-animation: slide-in-right 1s ease;
          animation: slide-in-right 1s ease;
}

.ImageLeft img {
  width: 100%;
  border: 5px solid #9F6256;
  border-radius: 5px;
  box-sizing: border-box;
}

.ImageLeft {
  width: 45%;
  -webkit-animation: slide-in-left 1s ease;
          animation: slide-in-left 1s ease;
}

.ImageTop img {
  width: 100%;
  border: 5px solid #9F6256;
  border-radius: 5px;
  box-sizing: border-box;
}

.ImageTop {
  width: 45%;
  -webkit-animation: slide-in-top 1s ease;
          animation: slide-in-top 1s ease;
}

@-webkit-keyframes slide-in-left {
   0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-left {
   0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-right {
   0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-right {
   0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slide-in-top {
   0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-top {
   0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-in-bottom {
   0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-in-bottom {
   0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 799px) {
  .Content {
    flex-flow: column;
    align-items: center;
    padding: 0;
  }

  .Text {
    width: 70%;
  }

  .ImageTop, .ImageLeft {
    width: 70%;
  }
}

@media (min-width: 800px) and (min-height: 1100px) {
  .Content {
    flex-flow: column;
    align-items: center;
    padding: 0;
  }

  .Text {
    width: 70%;
  }

  .ImageTop, .ImageLeft {
    width: 70%;
  }
}

.SubComment_SubComment__2YxJb {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.SubComment_SubComment__2YxJb img {
  border-radius: 50%;
  width: 35px;
  padding: 10px 0;
  margin-right: 15px;
}

.SubComment_Comment__1MiWf {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-left: 10%;
}

.SubComment_Heading__3V70D {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 0;
  padding: 0;
}

.SubComment_Heading__3V70D h4 {
  font: 14px 'Abril Fatface', cursive;
  margin: 0;
  margin-right: 10px;
  padding: 0;
}

.SubComment_Heading__3V70D p {
  font: 12px 'Inter', sans-serif;
  color: #7C3225;
  margin: 0;
  padding: 0;
}

.SubComment_Details__2TnZ- {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.SubComment_Content__3zqvq {
  width: calc(90% - 35px);
}

.SubComment_Content__3zqvq p {
  font: 12px 'Inter', sans-serif;
  color: #000;
  text-align: left;
}

.Button_Button__3gFiX {
  background-color: #BD9578;
  color: #FFF;
  height: 45px;
  font: bold 14px 'Inter', sans-serif;
  border-radius: 5px;
  border: 1px solid #9F6256;
  cursor: pointer;
}

.Button_Button__3gFiX:hover, .Button_Button__3gFiX:focus {
  border: 2px solid #7C3225;
}

.Button_Button__3gFiX:disabled {
  cursor: not-allowed;
}

.Button_Submit__2C2wf {
  width: 100%;
}

.Button_Comment__txpjD, .Button_Reply__12vcd {
  margin-top: 10px;
}

@media (max-width: 700px) {
  .Button_Comment__txpjD {
    width: 90%;
  }

  .Button_Reply__12vcd {
    width: 100%;
  }
}

.Modal_Modal__1-5dN {
  position: fixed;
  top: 30vh;
  left: calc(50% - 15rem);
  width: 30rem;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  z-index: 100;
  border-radius: 7px;
}

.Modal_Modal__1-5dN h2 {
  margin: 0;
  padding: 5%;
  background: #BD9578;
  color: white;
  border-radius: 7px 7px 0 0;
}

.Modal_Modal__1-5dN p {
    padding: 10px;
}

.Modal_Action__2UU6G {
    display: flex;
  justify-content: flex-end;
    padding: 5px 10px;
}

.Modal_Modal__1-5dN button {
  font: inherit;
  background: #BD9578;
  padding: 8px 20px;
  color: white;
  border: 1px solid #BD9578;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
}

.Modal_Modal__1-5dN button:hover,
button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
}

.Modal_Modal__1-5dN button:focus {
  outline: none;
}

@media (max-width: 570px) {
  .Modal_Modal__1-5dN {
    left: calc(50% - 7.5rem);
    width: 250px;
  }

  .Modal_Modal__1-5dN h2 {
    font-size: 16px;
  }

  .Modal_Modal__1-5dN p {
    font-size: 12px;
  }

  .Modal_Modal__1-5dN button {
    font-size: 12px;
  }
}

.Reply_Preview__XYTtL {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 32%;
  padding: 0 20px;
  margin: 10px 5px;
  box-sizing: border-box;
  cursor: pointer;
}


.Reply_Preview__XYTtL h1 {
  font: bold 20px 'Playfair Display', sans-serif;
  color: #000;
  text-align: left;
  margin: 0;
}

.Reply_Preview__XYTtL h3 {
  font: bold 16px 'Inter', sans-serif;
  color: #9F6256;
  margin: 0 0 20px 0;
}

.Reply_Preview__XYTtL p {
  font: 14px 'Inter', sans-serif;
  text-align: left;
  width: 100%;
}

.Reply_Reply__1USzk, .Reply_Comment___KXtG {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
}

.Reply_Comment___KXtG {
  width: 100%;
}

.Reply_Reply__1USzk input, .Reply_Comment___KXtG input {
  width: 90%;
  box-sizing: border-box;
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  background-color: #FFF;
  padding: 10px;
  margin-top: 10px;
}

.Reply_Reply__1USzk input:focus,
.Reply_Reply__1USzk input:hover,
.Reply_Comment___KXtG input:focus,
.Reply_Comment___KXtG input:hover {
  outline: 1px solid #9F6256;
}

.Reply_Reply__1USzk textarea,
.Reply_Comment___KXtG textarea {
  border: 1px solid #CCC;
  border-radius: 2px;
  font: 14px 'Inter', sans-serif;
  padding: 10px;
  box-sizing: border-box;
  width: 90%;
}

.Reply_Reply__1USzk textarea:hover,
.Reply_Reply__1USzk textarea:focus,
.Reply_Comment___KXtG textarea:hover,
.Reply_Comment___KXtG textarea:focus {
  outline: 1px solid #9F6256;
}

.Reply_Reply__1USzk button[type=cancel],
.Reply_Comment___KXtG button[type=cancel] {
  background-color: #FFF;
  color: #9F6256;
  font: 18px 'Abril Fatface', cursive;
  border: none;
  outline: none;
}

.Reply_Reply__1USzk button[type=cancel]:hover,
.Reply_Reply__1USzk button[type=cancel]:focus,
.Reply_Comment___KXtG button[type=cancel]:hover,
.Reply_Comment___KXtG button[type=cancel][type=cancel]:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.Reply_Header__3EKsD {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.Reply_Header__3EKsD h3 {
  font: 18px 'Abril Fatface', cursive;
  text-align: left;
}

.Reply_Options__2CUFZ {
  width: 60%;
  text-align: left;
}

.Reply_Options__2CUFZ input[type=checkbox] {
  width: auto;
  padding: 0;
  margin: 0;
}

.Reply_Options__2CUFZ input[type=checkbox]:hover {
  cursor: pointer;
  outline: none;
}

.Reply_Options__2CUFZ input[type=checkbox]:checked {
  background-color: #BD9578;
}

.Reply_Options__2CUFZ label {
  font: 12px 'Inter', sans-serif;
  text-align: left;
  margin: 0;
  padding: 0;
  width: 90%;
}

.Reply_Submit__289Ln {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 30px 0 40px 0;
}

@media (max-width: 700px) {
  .Reply_Reply__1USzk {
    width: 90%;
  }

  .Reply_Comment___KXtG {
    width: 100%;
  }

  .Reply_Reply__1USzk button[type=cancel],
  .Reply_Comment___KXtG button[type=cancel] {
    font-size: 16px;
  }

  .Reply_Reply__1USzk input {
    width: 100%;
  }

  .Reply_Reply__1USzk textarea {
    width: 100%;
  }

  .Reply_Header__3EKsD {
    width: 100%;
  }

  .Reply_Header__3EKsD h3 {
    font-size: 16px;
  }

  .Reply_Options__2CUFZ {
    width: 100%;
  }

  .Reply_Submit__289Ln {
    flex-flow: column;
    align-items: flex-start;
    width: 100%;
  }
}

.Comment_Comment__1xb1J {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Comment_Heading__2Oltr {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.Comment_Heading__2Oltr h4 {
  font: 14px 'Abril Fatface', cursive;
  margin: 0;
  margin-right: 10px;
  padding: 0;
}

.Comment_Heading__2Oltr p {
  font: 12px 'Inter', sans-serif;
  color: #7C3225;
  margin: 0;
  padding: 0;
}

.Comment_Heading__2Oltr button {
  font: 12px 'Inter', sans-serif;
  color: #7C3225;
  background-color: #FFF;
  border: none;
  margin: 0;
  padding: 0;
}

.Comment_Heading__2Oltr button:hover,
.Comment_Heading__2Oltr button:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.Comment_Details__1Jn9r {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 70%;
}

.Comment_Content__2iHv6 {
  width: 100%;
}

.Comment_Content__2iHv6 p {
  font: 12px 'Inter', sans-serif;
  color: #000;
  text-align: left;
}

.Comments_Comments__2ZZmP {
  display: flex;
  flex-flow: column;
 justify-content: space-evenly;
  align-items: center;
  width: 50%;
  margin: 10px auto 30px auto;
  box-sizing: border-box;
}

.Comments_Comments__2ZZmP h2 {
  font: bold 18px 'Inter', sans-serif;
  color: #000;
}

@media (max-width: 700px) {
  .Comments_Comments__2ZZmP {
    width: 80%;
  }
}

.Post_Post__2uao7 {
  display: flex;
  flex-flow: column;
justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 0 20px 0;
  box-sizing: border-box;
  -webkit-animation: Post_fade-in__3Eoxy 0.3s ease;
          animation: Post_fade-in__3Eoxy 0.3s ease;
}

.Post_Post__2uao7:focus {
  outline: 2px dotted #F5F4F4;
}

.Post_Post__2uao7 h1 {
  font: bold 28px 'Playfair Display', sans-serif;
  color: #000;
  margin: 0 10px;
}

.Post_Post__2uao7 h3 {
  font: bold 16px 'Inter', sans-serif;
  color: #9F6256;
  margin-bottom: 20px;
}

.Post_Post__2uao7 p {
  font: 14px 'Inter', sans-serif;
  text-align: left;
  width: 70%;
}

.Post_Post__2uao7 p img {
  width: 100%;
  margin: 10px 0;
}

.Post_Post__2uao7 a {
  color: #7C3225;
  text-decoration: none;
}

.Post_Feature__1fQy5 {
  display: flex;
  flex-flow: row;
justify-content: center;
  align-items: center;
  width: 70%;
  margin-bottom: 10px;
}

@-webkit-keyframes Post_fade-in__3Eoxy {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes Post_fade-in__3Eoxy {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 700px) {
  .Post_Post__2uao7 h1 {
    font-size: 18px;
  }

  .Post_Post__2uao7 h3 {
    font-size: 14px;
  }

  .Post_Post__2uao7 p {
    font-size: 12px;
    text-align: justify;
    width: 80%;
  }

  .Post_Feature__1fQy5 {
    width: 80%;
  }
}

.PostPreview_Preview__2pqMs {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  width: 32%;
  padding: 0 20px;
  margin: 10px 5px;
  box-sizing: border-box;
  cursor: pointer;
  min-height: 390px;
  -webkit-animation: PostPreview_fade-in__21w8r 0.8s ease;
          animation: PostPreview_fade-in__21w8r 0.8s ease;
}

.PostPreview_Preview__2pqMs:focus {
  outline: 2px dotted #F5F4F4;
}

.PostPreview_Preview__2pqMs h1 {
  font: bold 20px 'Playfair Display', sans-serif;
  color: #000;
  text-align: left;
  margin: 0;
}

.PostPreview_Preview__2pqMs h3 {
  font: bold 16px 'Inter', sans-serif;
  color: #9F6256;
  margin: 0 0 20px 0;
}

.PostPreview_Preview__2pqMs p {
  font: 14px 'Inter', sans-serif;
  text-align: left;
  width: 100%;
}

.PostPreview_Preview__2pqMs img {
  width: 100%;
  margin-bottom: 10px;
}

.PostPreview_Preview__2pqMs a {
  color: #7C3225;
  text-decoration: none;
}

@-webkit-keyframes PostPreview_fade-in__21w8r {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes PostPreview_fade-in__21w8r {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 625px) and (max-width: 1000px) {
  .PostPreview_Preview__2pqMs{
    width: 45%;
    margin: 10px 10px 10px 15px;
  }
}

@media (max-width: 624px) {
  .PostPreview_Preview__2pqMs {
    width: 100%;
    margin: 10px 0;
    min-height: 410px;
  }
}

.BlogItems_BlogItems__2pgMO {
  display: flex;
  flex-flow: row wrap;
 justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 20px 20px 10px 20px;
  list-style: none;
  width: 80%;
  -webkit-animation: BlogItems_fade-in__3skAA 0.8s ease;
          animation: BlogItems_fade-in__3skAA 0.8s ease;
}

@-webkit-keyframes BlogItems_fade-in__3skAA {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes BlogItems_fade-in__3skAA {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 570px) {
  .BlogItems_BlogItems__2pgMO {
  justify-content: center;
  }
}

.BlogItem_BlogItem__28aHI {
  margin: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.BlogItem_BlogItem__28aHI button {
  color: #000;
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
  font: 600 14px 'Inter', sans-serif;
  background-color: #FFF;
}

.BlogItem_BlogItem__28aHI button:hover,
.BlogItem_BlogItem__28aHI button:active,
.BlogItem_BlogItem__28aHI button.BlogItem_active__3gZ7J,
.BlogItem_BlogItem__28aHI button:focus {
  color: #9F6256;
  border: none;
  outline: none;
  box-shadow: none;
}

.Blog_Content__1vHsb {
  display: flex;
  flex-direction: column;
justify-content: center;
 align-items: center;
  width: 95%;
  min-height: 90vh;
  margin: 20px auto;
  padding: 0;
  -webkit-animation: Blog_fade-in__1STDK 0.8s ease;
          animation: Blog_fade-in__1STDK 0.8s ease;
}

 @-webkit-keyframes Blog_fade-in__1STDK {
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }

 @keyframes Blog_fade-in__1STDK {
   0% {
     opacity: 0;
   }
   100% {
     opacity: 1;
   }
 }

@media (max-width: 624px) {
  .Blog_Content__1vHsb {
    width: 85%;
  }
}

.Footer_Footer__238hM {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 30px 45px;
  margin: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.9);
  font: 18px "Inter", sans-serif;
  text-align: center;
  z-index: 90;
}

.Footer_Footer__238hM h2 {
  font: bold 36px "Brittany";
  margin: 0;
}

.Footer_Footer__238hM h3 {
  font: bold 24px "Brittany";
  margin: 0;
  margin-bottom: 20px;
}

.Footer_Subscribe__3fUZy {
  width: 20%;
  height: 50%;
}

.Footer_Signature__1cCgV {
  height: 100%;
}

.Footer_SocialBar__Wmo5r {
  height: 50%;
}

@media (max-width: 700px) {
  .Footer_Footer__238hM {
    font-size: 12px;
    padding: 30px;
  }

  .Footer_Footer__238hM h2 {
    font-size: 32px;
  }

  .Footer_Subscribe__3fUZy {
    display: none;
  }

  .Footer_SocialBar__Wmo5r {
    display: none;
  }
}

.App {
  text-align: center;
  padding-bottom: 200px;
  position: relative;
  box-sizing: border-box;
  min-height: 100%;
}

@media (max-width: 700px) {
  .App {
    padding-bottom: 158px;
  }
}

