.Container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 30px 0;
  padding: 30px;
}

.Loader {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-animation: Loader 2.5s infinite linear both;
  animation: Loader 2.5s infinite linear both;
}

.LoaderDot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: LoaderDot 2.0s infinite ease-in-out both;
  animation: LoaderDot 2.0s infinite ease-in-out both;
}

.LoaderDot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #000;
  border-radius: 100%;
  -webkit-animation: LoaderDot-before 2.0s infinite ease-in-out both;
  animation: LoaderDot-before 2.0s infinite ease-in-out both;
}

.LoaderDot:nth-child(1) { -webkit-animation-delay: -1.1s;animation-delay: -1.1s; }
.LoaderDot:nth-child(2) { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
.LoaderDot:nth-child(3) { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
.LoaderDot:nth-child(4) { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
.LoaderDot:nth-child(5) { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
.LoaderDot:nth-child(6) { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }
.LoaderDot:nth-child(1):before { -webkit-animation-delay: -1.1s; animation-delay: -1.1s; }
.LoaderDot:nth-child(2):before { -webkit-animation-delay: -1.0s; animation-delay: -1.0s; }
.LoaderDot:nth-child(3):before { -webkit-animation-delay: -0.9s; animation-delay: -0.9s; }
.LoaderDot:nth-child(4):before { -webkit-animation-delay: -0.8s; animation-delay: -0.8s; }
.LoaderDot:nth-child(5):before { -webkit-animation-delay: -0.7s; animation-delay: -0.7s; }
.LoaderDot:nth-child(6):before { -webkit-animation-delay: -0.6s; animation-delay: -0.6s; }

@-webkit-keyframes Loader {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Loader {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes LoaderDot {
  80%, 100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes LoaderDot {
  80%, 100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes LoaderDot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  } 100%, 0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

@keyframes LoaderDot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  } 100%, 0% {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}
