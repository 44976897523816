.Categories {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
         align-items: center;
  margin: 0;
  padding: 5% 1%;
  height: 100%;
  list-style: none;
}

@media (min-width: 800px) {
  .Categories {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
       -ms-flex-flow: row wrap;
           flex-flow: row wrap;
    -webkit-box-pack: space-evenly;
       -ms-flex-pack: space-evenly;
     justify-content: space-evenly;
  }
}
