.Logo {
  padding: 0;
  height: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Logo img {
  height: 100%;
}
