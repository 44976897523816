.Post {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
     -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  width: 100%;
  padding: 40px 0 20px 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-animation: fade-in 0.3s ease;
          animation: fade-in 0.3s ease;
}

.Post:focus {
  outline: 2px dotted #F5F4F4;
}

.Post h1 {
  font: bold 28px 'Playfair Display', sans-serif;
  color: #000;
  margin: 0 10px;
}

.Post h3 {
  font: bold 16px 'Inter', sans-serif;
  color: #9F6256;
  margin-bottom: 20px;
}

.Post p {
  font: 14px 'Inter', sans-serif;
  text-align: left;
  width: 70%;
}

.Post p img {
  width: 100%;
  margin: 10px 0;
}

.Post a {
  color: #7C3225;
  text-decoration: none;
}

.Feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
     -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row;
          flex-flow: row;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  width: 70%;
  margin-bottom: 10px;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 700px) {
  .Post h1 {
    font-size: 18px;
  }

  .Post h3 {
    font-size: 14px;
  }

  .Post p {
    font-size: 12px;
    text-align: justify;
    width: 80%;
  }

  .Feature {
    width: 80%;
  }
}
