.Comments {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
   -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  width: 50%;
  margin: 10px auto 30px auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.Comments h2 {
  font: bold 18px 'Inter', sans-serif;
  color: #000;
}

@media (max-width: 700px) {
  .Comments {
    width: 80%;
  }
}
