.Welcome {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  position: relative;
}

.Welcome img {
  width: 100%;
  height: 500px;
}

.Transbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
     -ms-flex-pack: center;
   justify-content: center;
  -webkit-box-align: center;
     -ms-flex-align: center;
        align-items: center;
  width: 100%;
  background-color: rgba(220, 204, 192, 0.75);
  -webkit-animation: fade-in 0.8s ease;
          animation: fade-in 0.8s ease;
}

.Transbox h1 {
  font: bold 48px 'Brittany';
  text-shadow: 1px 2px rgba(124, 50, 37, 0.4);
  color: #7C3225;
  -webkit-transform: rotate(-5deg);
      -ms-transform: rotate(-5deg);
          transform: rotate(-5deg);
}

.Transbox p {
  font: 400 18px/140% 'Open Sans', sans-serif;
  padding-bottom: 5%;
  width: 75%;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 799px) {
  .Welcome img {
    display: none;
  }
}

@media (min-width: 800px) {
  .Transbox h1 {
    font-size: 58px;
  }

  .Transbox {
    position: absolute;
    top: 15%;
    left: 10%;
    width: 80%;
    border-radius: 5px;
  }
}
