.NavigationItems {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
         align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  height: 100%;
}

@media (min-width: 570px) {
  .NavigationItems {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row;
            flex-flow: row;
    -webkit-box-pack: justify;
       -ms-flex-pack: justify;
     justify-content: space-between;
    width: 550px;
  }
}
