.SearchBar {
  width: 150px;
  position: absolute;
  right: 0;
  bottom: -45px;
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.SearchBar input {
  width: 100%;
  padding: 10px;
  font: 12px 'Inter', sans-serif;
  background-color: #FFF;
  border: 1px solid rgb(230, 230, 230);
  -webkit-box-shadow: 0px 1px 2px #CCC;
          box-shadow: 0px 1px 2px #CCC;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.SearchBar input:focus, input:hover {
  outline: 1px solid #9F6256;
}

.Show {
  opacity: 1;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

@media (max-width: 499px) {
  .SearchBar {
    right: -10px;
    bottom: -20px;
  }
}
